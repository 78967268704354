<template>
  <div class="chartContainer">
    <div :style="'min-width:1160px'">
      <b-form-input
        v-if="$route.path === '/'"
        id="ascendant"
        v-model="siderealAscendant"
        type="number"
        min="1"
        max="12"
      ></b-form-input>
      <p class="boldFont">{{ year }} Year Long Cycles</p>
      <b-form-input
        v-if="$route.path === '/'"
        id="range-1"
        v-model="signSliderValue"
        type="range"
        min="-10"
        max="100"
      ></b-form-input>
      <div :class="zodiacMode">
        <div
          v-if="$route.path === '/'"
          class="signs"
          :style="'left:' + signSliderValue + 'vw'"
        >
          <div v-if="zodiacMode === 'siderealElementalSignsBackground'">
            <span class="signLabel">PISCES {{ calculateHouse(12) }}</span>
            <span class="signLabel">AQUARIUS {{ calculateHouse(11) }}</span>
            <span class="signLabel">CAPRICORN {{ calculateHouse(10) }}</span>
            <span class="signLabel">SAGITTARIUS {{ calculateHouse(9) }}</span>
            <span class="signLabel">SCORPIO {{ calculateHouse(8) }}</span>
            <span class="signLabel">LIBRA {{ calculateHouse(7) }}</span>
            <span class="signLabel">VIRGO {{ calculateHouse(6) }}</span>
            <span class="signLabel">LEO {{ calculateHouse(5) }}</span>
            <span class="signLabel">CANCER {{ calculateHouse(4) }}</span>
            <span class="signLabel">GEMINI {{ calculateHouse(3) }}</span>
            <span class="signLabel">TAURUS {{ calculateHouse(2) }}</span>
            <span class="signLabel">ARIES {{ calculateHouse(1) }}</span>
          </div>
          <div v-if="zodiacMode === 'tropicalElementalSignsBackground'">
            <span class="signLabel">ARIES {{ calculateHouse(1) }}</span>
            <span class="signLabel">PISCES {{ calculateHouse(12) }}</span>
            <span class="signLabel">AQUARIUS {{ calculateHouse(11) }}</span>
            <span class="signLabel">CAPRICORN {{ calculateHouse(10) }}</span>
            <span class="signLabel">SAGITTARIUS {{ calculateHouse(9) }}</span>
            <span class="signLabel">SCORPIO {{ calculateHouse(8) }}</span>
            <span class="signLabel">LIBRA {{ calculateHouse(7) }}</span>
            <span class="signLabel">VIRGO {{ calculateHouse(6) }}</span>
            <span class="signLabel">LEO {{ calculateHouse(5) }}</span>
            <span class="signLabel">CANCER {{ calculateHouse(4) }}</span>
            <span class="signLabel">GEMINI {{ calculateHouse(3) }}</span>
            <span class="signLabel">TAURUS {{ calculateHouse(2) }}</span>
          </div>
          <div v-if="zodiacMode === 'comboElementalSignsBackground'">
            <span class="signLabel"
              >PISCES/ARIES {{ calculateHouse([1, 12]) }}</span
            >
            <span class="signLabel">
              AQUARIUS/PISCES {{ calculateHouse([12, 11]) }}
            </span>
            <span class="signLabel">
              CAPRICORN/AQUARIUS {{ calculateHouse([11, 10]) }}
            </span>
            <span class="signLabel">
              SAGITTARIUS/CAPRICORN {{ calculateHouse([10, 9]) }}
            </span>
            <span class="signLabel">
              SCORPIO/SAGITTARIUS {{ calculateHouse([9, 8]) }}
            </span>
            <span class="signLabel"
              >LIBRA/SCORPIO {{ calculateHouse([8, 7]) }}</span
            >
            <span class="signLabel"
              >VIRGO/LIBRA {{ calculateHouse([7, 6]) }}</span
            >
            <span class="signLabel"
              >LEO/VIRGO {{ calculateHouse([6, 5]) }}</span
            >
            <span class="signLabel"
              >CANCER/LEO {{ calculateHouse([5, 4]) }}</span
            >
            <span class="signLabel"
              >GEMINI/CANCER {{ calculateHouse([4, 3]) }}</span
            >
            <span class="signLabel"
              >TAURUS/GEMINI {{ calculateHouse([3, 2]) }}</span
            >
            <span class="signLabel"
              >ARIES/TAURUS {{ calculateHouse([2, 1]) }}</span
            >
          </div>
        </div>
        <LineChart
          :chartData="chartData"
          :options="options2"
          :styles="chartStyles2"
          ref="chartComponent"
        ></LineChart>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "./core/LineChart";
import useEphemeris from "@/composables/useEphemeris";
import dayjs from "dayjs";
import {
  planetColorsMap,
  signLetterCodeToNumber,
  signAndDegreeTo360,
} from "../../assets/helpers";

export default {
  name: "YearLong360LineChart",
  setup() {
    const { currentMonthObj, currentYearObj, findYearObj } = useEphemeris();
    return {
      currentMonthObj,
      currentYearObj,
      findYearObj,
    };
  },
  components: {
    LineChart,
  },
  props: ["year", "zodiacMode"],
  data() {
    return {
      signLetterCodeToNumber,
      signAndDegreeTo360,
      chartStyles2: {
        height: "760px",
        minWidth: "1160px",
        position: "relative",
      },
      signSliderValue: 0,
      siderealAscendant: null,
    };
  },
  methods: {
    calculateHouse(sign) {
      if (this.siderealAscendant) {
        if (this.zodiacMode === "siderealElementalSignsBackground") {
          if (sign + 1 > this.siderealAscendant) {
            return sign + 1 - this.siderealAscendant;
          } else {
            return sign + 1 + 12 - this.siderealAscendant;
          }
        }
        if (this.zodiacMode === "tropicalElementalSignsBackground") {
          if (sign > this.siderealAscendant) {
            return sign - this.siderealAscendant;
          } else {
            return sign + 12 - this.siderealAscendant;
          }
        }
        if (this.zodiacMode === "comboElementalSignsBackground") {
          if (sign[0] > this.siderealAscendant) {
            return sign[0] - this.siderealAscendant;
          } else {
            return sign[0] + 12 - this.siderealAscendant;
          }
        }
      }
    },
  },
  computed: {
    chartData() {
      if (this.findYearObj(this.year) !== {}) {
        let selectedYear = this.findYearObj(this.year);
        let datesArray = [];
        let planets = Object.keys(selectedYear.months[0].data[0]).filter(
          (key) => key !== "date"
        );
        let generatedDatasets = [];
        planets.forEach((planet) => {
          let planetChartObj = {
            label: planet,
            data: [],
            borderColor: planetColorsMap[planet],
            backgroundColor: planetColorsMap[planet],
            fill: false,
            pointRadius: 6,
          };
          generatedDatasets.push(planetChartObj);
        });
        // for each planet
        generatedDatasets.forEach((chartDatasetObj) => {
          let planet = chartDatasetObj.label;
          selectedYear.months.forEach((monthData) => {
            // for each month
            let thsMonthDatesArray = monthData.data.map((obj) =>
              dayjs(obj.date.slice(0, obj.date.length - 2).trim()).format(
                "YYYY-MM-DD"
              )
            );
            datesArray = datesArray.concat(thsMonthDatesArray);
            monthData.data.forEach((date) => {
              chartDatasetObj.data.push(this.signAndDegreeTo360(planet, date));
            });
          });
        });

        return {
          labels: datesArray,
          datasets: generatedDatasets,
        };
      } else {
        return {};
      }
    },
    options2() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
        },
        plugins: {
          datalabels: {
            color: "#FFF",
            display: true,
          },
        },
        tooltips: {
          mode: "index",
          intersect: true,
          callbacks: {
            title: (tooltipItem) => {
              return dayjs(tooltipItem[0].label).format("MMM D YYYY dd");
            },
            label: (tooltipItem, data) => {
              // reverse engineer sign from 360
              let signsMap = {
                siderealElementalSignsBackground: {
                  1: "aries",
                  2: "taurus",
                  3: "gemini",
                  4: "cancer",
                  5: "leo",
                  6: "virgo",
                  7: "libra",
                  8: "scorpio",
                  9: "sagittarius",
                  10: "capricorn",
                  11: "aquarius",
                  12: "pisces",
                },
                tropicalElementalSignsBackground: {
                  1: "taurus",
                  2: "gemini",
                  3: "cancer",
                  4: "leo",
                  5: "virgo",
                  6: "libra",
                  7: "scorpio",
                  8: "sagittarius",
                  9: "capricorn",
                  10: "aquarius",
                  11: "pisces",
                  12: "aries",
                },
                comboElementalSignsBackground: {
                  1: "aries/taurus",
                  2: "taurus/gemini",
                  3: "gemini/cancer",
                  4: "cancer/leo",
                  5: "leo/virgo",
                  6: "virgo/libra",
                  7: "libra/scorpio",
                  8: "scorpio/sagittarius",
                  9: "sagittarius/capricorn",
                  10: "capricorn/aquarius",
                  11: "aquarius/pisces",
                  12: "pisces/aries",
                },
              };
              if (tooltipItem.value >= 0 && tooltipItem.value <= 30) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["1"]
                );
              }
              if (tooltipItem.value >= 30 && tooltipItem.value <= 60) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["2"]
                );
              }
              if (tooltipItem.value >= 60 && tooltipItem.value <= 90) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["3"]
                );
              }
              if (tooltipItem.value >= 90 && tooltipItem.value <= 120) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["4"]
                );
              }
              if (tooltipItem.value >= 120 && tooltipItem.value <= 150) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["5"]
                );
              }
              if (tooltipItem.value >= 150 && tooltipItem.value <= 180) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["6"]
                );
              }
              if (tooltipItem.value >= 180 && tooltipItem.value <= 210) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["7"]
                );
              }
              if (tooltipItem.value >= 210 && tooltipItem.value <= 240) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["8"]
                );
              }
              if (tooltipItem.value >= 240 && tooltipItem.value <= 270) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["9"]
                );
              }
              if (tooltipItem.value >= 270 && tooltipItem.value <= 300) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["10"]
                );
              }
              if (tooltipItem.value >= 300 && tooltipItem.value <= 330) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["11"]
                );
              }
              if (tooltipItem.value >= 330 && tooltipItem.value <= 360) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  " in " +
                  signsMap[this.zodiacMode]["12"]
                );
              }
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 30,
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              offset: true,
              type: "time",
              time: {
                unit: "month",
              },
            },
          ],
        },
      };
    },
  },
};
</script>
<style>
.siderealElementalSignsBackground {
  background: url("../../assets/elements-background-light.png");
  background-size: 2838px 760px;
  background-repeat: no-repeat;
}
.tropicalElementalSignsBackground {
  background: url("../../assets/elements-background-tropical.png");
  background-size: 2838px 760px;
  background-repeat: no-repeat;
}
.comboElementalSignsBackground {
  background: url("../../assets/elements-background-combo.png");
  background-size: 2838px 760px;
  background-repeat: no-repeat;
}
.signs {
  position: absolute;
  top: 133px;
  line-height: 2.6rem;
  z-index: 2000;
  text-align: right;
  border-right: 9px solid black;
  padding-top: 7.5px;
}
.signLabel {
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-bottom: 16px;
  padding: 0;
  padding-right: 1rem;
}
</style>
