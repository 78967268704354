<template>
  <div id="app">
    <div id="nav">
      <div>
        <router-link to="/">Home</router-link> |
        <router-link to="/ephemeris">Ephemeris</router-link> |
        <router-link to="/yearsScroll">Years Scroll</router-link> |
        <!-- <router-link to="/about">About</router-link> -->
      </div>
      <div>
        <p>
          {{ dayjs().format("dddd MMM D") }} {{ dayjs().format("MM-DD-YYYY") }}
        </p>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="mainContent">
      <router-view />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "App",
  methods: {
    dayjs,
  },
};
</script>
<style>
#app {
  overflow-x: hidden;
  width: 100vw;
}
#nav {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100vw;
}
.mainContent {
  width: 100vw;
  overflow: scroll;
  padding-bottom: 2rem;
}
</style>
