<template>
  <div class="chartContainer" v-if="currentMonthObj">
    <h2>{{ currentMonthObj.label }}</h2>
    <br />
    <b-table
      small
      striped
      hover
      :items="currentMonthObj.data"
      :fields="ephemerisTableFields"
    >
      <template #head(date)="data">
        <span :style="'font-family: Physis !important; font-size: 1rem;'">{{
          data.label
        }}</span>
      </template>
      <template #cell(date)="data">
        <span :style="'font-family: DyslexicRegular; font-size: .85rem;'">{{
          data.value
        }}</span>
      </template>
      <template #cell()="data">
        <span
          :class="data.value.direction === 'R' ? 'retrograde' : ''"
          v-if="data.value.sign"
        >
          <span :class="decodeSymbolLetter(data.value.sign)[1]">
            <span class="physis-icon">{{
              decodeSymbolLetter(data.value.sign)[0]
            }}</span>
            {{ data.value.degree }}
            <span v-if="data.value.direction === 'R'">{{
              data.value.direction
            }}</span>
          </span>
        </span>
      </template>
    </b-table>

    <br />
  </div>
</template>
<script>
import useEphemeris from "@/composables/useEphemeris";
import { decodeSymbolLetter } from "@/assets/helpers";

export default {
  name: "SelectedMonthEphemerisTable",
  setup() {
    const { currentDayObj, findMonthObj, ephemerisTableFields } =
      useEphemeris();
    return {
      currentDayObj,
      findMonthObj,
      ephemerisTableFields,
    };
  },
  props: ["selectedDate"],
  data() {
    return {};
  },
  methods: {
    decodeSymbolLetter,
  },
  computed: {
    currentMonthObj() {
      return this.findMonthObj(this.selectedDate);
    },
  },
};
</script>
