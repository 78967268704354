<template>
  <div class="home">
    <b-container fluid="true">
      <b-row>
        <b-col>
          <div :style="'display:flex'">
            <b-button
              size="sm"
              variant="outline-primary"
              :disabled="dayjs(value).format('YYYY') * 1 <= 1950"
              @click="
                value = dayjs(value).subtract(1, 'day').format('YYYY-MM-DD')
              "
              >last
            </b-button>
            <b-form-datepicker
              id="example-datepicker"
              v-model="value"
              class="mb-2"
              :min="'1-1-2000'"
              :max="'1-1-2100'"
            ></b-form-datepicker>
            <b-button
              size="sm"
              variant="outline-primary"
              :disabled="dayjs(value).format('YYYY') * 1 >= 2100"
              @click="value = dayjs(value).add(1, 'day').format('YYYY-MM-DD')"
              >next
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="4">
          <SouthIndianChartOfTheDay
            class="centerWithSmallMargin"
            :selectedDate="dayjs(value).format('YYYY-MM-DD')"
          />
        </b-col>
        <b-col sm="12" md="12" lg="12" xl="8">
          <StrengthsDetailsBoxes
            class="centerWithSmallMargin"
            :selectedDate="dayjs(value).format('YYYY-MM-DD')"
          />
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <span
            :class="
              zodiacMode === 'siderealElementalSignsBackground'
                ? 'activeViewMode'
                : ''
            "
            @click="zodiacMode = 'siderealElementalSignsBackground'"
          >
            SIDEREAL
          </span>
          <span
            :class="
              zodiacMode === 'tropicalElementalSignsBackground'
                ? 'activeViewMode'
                : ''
            "
            @click="zodiacMode = 'tropicalElementalSignsBackground'"
          >
            TROPICAL
          </span>
          <span
            :class="
              zodiacMode === 'comboElementalSignsBackground'
                ? 'activeViewMode'
                : ''
            "
            @click="zodiacMode = 'comboElementalSignsBackground'"
          >
            COMBO
          </span>
          <div :class="'horizontalScrollDiv'">
            <YearLong360LineChart
              :year="dayjs(value).format('YYYY')"
              :zodiacMode="zodiacMode"
            />
          </div>
          <div :class="'horizontalScrollDiv'">
            <SelectedMonthEphemerisTable
              :selectedDate="dayjs(value).format('YYYY-MM-DD')"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import dayjs from "dayjs";
import YearLong360LineChart from "../components/charts/YearLong360LineChart.vue";
import SouthIndianChartOfTheDay from "../components/charts/SouthIndianChartOfTheDay.vue";
import StrengthsDetailsBoxes from "../components/charts/StrengthsDetailsBoxes.vue";
import SelectedMonthEphemerisTable from "../components/charts/SelectedMonthEphemerisTable.vue";
import { encodePlanetSymbol } from "@/assets/helpers";

export default {
  name: "Home",
  components: {
    YearLong360LineChart,
    SouthIndianChartOfTheDay,
    StrengthsDetailsBoxes,
    SelectedMonthEphemerisTable,
  },
  data() {
    return {
      value: dayjs().format("YYYY-MM-DD").toString(),
      zodiacMode: "siderealElementalSignsBackground",
    };
  },
  methods: {
    dayjs,
    encodePlanetSymbol,
  },
};
</script>
<style>
.centerWithSmallMargin {
  width: calc(100% - 2rem);
  margin-left: 1rem;
}
.horizontalScrollDiv {
  margin-top: 1rem;
  width: 100vw;
  overflow-x: scroll;
}
.activeViewMode {
  background-color: #111;
  color: #fff;
}
</style>
