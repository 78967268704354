import { computed } from "@vue/composition-api";
import dayjs from "dayjs";
// import { reactive, toRefs, shallowRef, computed} from "@vue/composition-api";
import { ephemerisRawData, months } from "../assets/siderealEphemeris";
import { parseForSymbols, monthLabelToNumberMap } from "@/assets/helpers";

const ephemerisTableFields = [
  {
    key: "date",
    label: "",
  },
  {
    key: "sun",
    label: "r",
  },
  {
    key: "moon",
    label: "q",
  },
  {
    key: "mercury",
    label: "w",
  },
  {
    key: "venus",
    label: "e",
  },
  {
    key: "mars",
    label: "t",
  },
  {
    key: "jupiter",
    label: "y",
  },
  {
    key: "saturn",
    label: "u",
  },
  {
    key: "uranus",
    label: "i",
  },
  {
    key: "neptune",
    label: "o",
  },
  {
    key: "pluto",
    label: "p",
  },
  {
    key: "rahu",
    label: "]",
  },
  {
    key: "lilith",
    label: "Y",
  },
  {
    key: "chiron",
    label: "T",
  },
];

const findYearObj = (year) => {
  return ephemerisWithDayObjects.value.find(
    (yearObj) => yearObj.year === year.toString()
  );
};
const findMonthObj = (date) => {
  let yearObj = ephemerisWithDayObjects.value.find(
    (yearObj) => yearObj.year === dayjs(date).format("YYYY").toString()
  );
  let monthObj = yearObj.months.find((monthObj) => {
    return monthLabelToNumberMap[monthObj.label] === dayjs(date).format("M");
  });
  return monthObj;
};
const findDayObj = (date) => {
  let yearObj = ephemerisWithDayObjects.value.find(
    (yearObj) => yearObj.year === dayjs(date).format("YYYY").toString()
  );
  let monthObj = yearObj.months.find((monthObj) => {
    return monthLabelToNumberMap[monthObj.label] === dayjs(date).format("M");
  });
  let dayObj = monthObj.data.find((dayObj) => {
    let dayObjDate = dayObj.date.slice(0, dayObj.date.length - 2);
    return (
      dayjs(dayObjDate).format("YYY-MM-DD") === dayjs(date).format("YYY-MM-DD")
    );
  });
  return dayObj;
};

const ephemerisDataMapped = computed(() => {
  // a method to 'read' the raw year strings and make em easy to work w
  return ephemerisRawData.map((yearObj) => {
    let arrayOfMonths = [];
    let rawMonthsArray = yearObj.string.split("UT Day");
    rawMonthsArray.forEach((monthString, index) => {
      if (monthString.indexOf("A B C D E F G O I J L K M N") >= 0) {
        let monthLabel = months.find((obj) => obj.num === index).label;
        let modifiedMonthString = monthString.substring(
          monthString.indexOf("Day") + 4,
          monthString.indexOf("Delta")
        );
        let startToBreakByDate = modifiedMonthString
          .split(/[SMTWF]/)
          .filter((str) => str.length > 4);
        startToBreakByDate = startToBreakByDate.map((dayString) =>
          dayString
            // get rid of extra spaces
            .replaceAll("° ", "°")
            .replaceAll("a ", "a")
            .replaceAll("b ", "b")
            .replaceAll("c ", "c")
            .replaceAll("d ", "d")
            .replaceAll("e ", "e")
            .replaceAll("f ", "f")
            .replaceAll("g ", "g")
            .replaceAll("h ", "h")
            .replaceAll("i ", "i")
            .replaceAll("j ", "j")
            .replaceAll("k ", "k")
            .replaceAll("l ", "l")
            .replaceAll("m ", "m")
            .replaceAll("n ", "n")
            .replaceAll("D ", "D")
            .replaceAll("R ", "R")
            .trim()
        );
        arrayOfMonths.push({
          label: monthLabel || "",
          data: startToBreakByDate.map((dayString) => {
            let dateArray = dayString.split(" ");
            // remove the first 4 elements cause it's uneeded (Sid. t?)
            dateArray.shift();
            dateArray.shift();
            dateArray.shift();
            dateArray.shift();
            //add the date to the first col
            let dateNumber = dayString.slice(0, dayString.indexOf(" "));
            let dayLabel = dayjs(
              monthLabel + " " + dateNumber + ", " + yearObj.year
            ).format("YYYY-M-D dd");
            dateArray.unshift(dayLabel);
            return {
              date: dateArray[0],
              sun: dateArray[1],
              moon: dateArray[2],
              mercury: dateArray[3],
              venus: dateArray[4],
              mars: dateArray[5],
              jupiter: dateArray[6],
              saturn: dateArray[7],
              uranus: dateArray[8],
              neptune: dateArray[9],
              pluto: dateArray[10],
              rahu: dateArray[11], // and this is true node
              // ketu: dateArray[12], commenting this out cause it's not actually ketu - it's mean node
              lilith: dateArray[13],
              chiron: dateArray[14],
            };
          }),
        });
      }
    });
    return {
      year: yearObj.year,
      months: arrayOfMonths,
    };
  });
});

const ephemerisWithDayObjects = computed(() => {
  if (ephemerisDataMapped.value) {
    let allEphemerisData = Object.assign(ephemerisDataMapped.value, {});
    allEphemerisData = allEphemerisData.sort((a, b) => a.year - b.year);
    let currentSignsObj = {
      sun: "",
      moon: "",
      mercury: "",
      venus: "",
      mars: "",
      jupiter: "",
      saturn: "",
      uranus: "",
      neptune: "",
      pluto: "",
      rahu: "",
      lilith: "",
      chiron: "",
    };
    let currentDirectionObj = {
      sun: "D",
      moon: "D",
      mercury: "D",
      venus: "D",
      mars: "D",
      jupiter: "D",
      saturn: "D",
      uranus: "D",
      neptune: "D",
      pluto: "D",
      rahu: "D",
      lilith: "D",
      chiron: "D",
    };
    allEphemerisData.forEach((yearObj) => {
      yearObj.months.forEach((monthObj) => {
        // iterate over the dateObjects to get a sign on every degree entry
        // CREATION OF DATE OBJECTS FROM STRINGS
        monthObj.data.forEach((dateObj) => {
          // for each date, look at each planet's position
          for (const planet in dateObj) {
            if (planet !== "date") {
              let element = dateObj[planet];
              // check for planetary direction
              if (element.indexOf("R") >= 0) {
                // planet is stationing retrograte
                currentDirectionObj[planet] = "R";
                element = element.replace("R", "");
              }
              if (element.indexOf("D") >= 0) {
                // planet is stationing direct
                currentDirectionObj[planet] = "D";
                element = element.replace("D", "");
              }
              // takes string like 12a59
              // and returns an array in the form of ['12', 'a', '59']
              let degreeStringSplitIntoArray = parseForSymbols(element);
              if (degreeStringSplitIntoArray.length > 1) {
                // we have a zodiac sign letter in here, so set it in the current obj
                currentSignsObj[planet] = degreeStringSplitIntoArray[1];
                dateObj[planet] = {
                  sign: degreeStringSplitIntoArray[1],
                  degree:
                    degreeStringSplitIntoArray[0] +
                    "°" +
                    degreeStringSplitIntoArray[2],
                  direction: currentDirectionObj[planet],
                };
              } else {
                // no zodiac sign in the degree string
                // do we have one in the counting obj?
                let oldString = element;
                dateObj[planet] = {
                  sign: currentSignsObj[planet],
                  degree: oldString,
                  direction: currentDirectionObj[planet],
                };
              }
            }
          }
        });
      });
    });
    return allEphemerisData;
  } else {
    return [];
  }
});
const currentYearObj = computed(() => {
  return ephemerisWithDayObjects.value.find(
    (yearObj) => yearObj.year === dayjs().format("YYYY")
  );
});
const currentMonthObj = computed(() => {
  return currentYearObj.value.months.find(
    (monthObj) => monthObj.label === dayjs().format("MMMM").toUpperCase()
  );
});
const currentDayObj = computed(() => {
  return currentMonthObj.value.data.find(
    //2022-06-01 Wednesday
    (dayObj) => dayObj.date === dayjs().format("YYYY-M-D dd")
  );
});

export default function useEphemeris() {
  return {
    ephemerisDataMapped,
    ephemerisTableFields,
    ephemerisWithDayObjects,
    currentYearObj,
    currentMonthObj,
    currentDayObj,
    findYearObj,
    findMonthObj,
    findDayObj,
  };
}
