import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Ephemeris from "../views/Ephemeris.vue";
import YearsScroll from "../views/YearsScroll.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ephemeris",
    name: "Ephemeris",
    component: Ephemeris,
  },
  {
    path: "/yearsScroll",
    name: "YearsScroll",
    component: YearsScroll,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
