<template>
  <div class="ephemeris">
    <div :style="'display:flex;'">
      <b-button
        variant="outline-primary"
        :disabled="selectedYear * 1 <= 2000"
        @click="selectedYear = (selectedYear * 1 - 1).toString()"
        >last
      </b-button>
      <b-form-select
        v-model="selectedYear"
        :options="yearOptions"
      ></b-form-select>
      <b-button
        variant="outline-primary"
        :disabled="selectedYear * 1 >= 2100"
        @click="selectedYear = (selectedYear * 1 + 1).toString()"
        >next
      </b-button>
    </div>
    <div
      v-for="(month, index) in selectedYearData.months"
      :key="index + 'monthDiv'"
    >
      <h2>{{ month.label }} {{ selectedYearData.year }}</h2>
      <b-table
        small
        sticky-header
        striped
        hover
        :items="month.data"
        :fields="ephemerisTableFields"
      >
        <template #head(date)="data">
          <span :style="'font-family: Physis !important; font-size: 1rem;'">{{
            data.label
          }}</span>
        </template>
        <template #cell(date)="data">
          <span :style="'font-family: DyslexicRegular; font-size: .85rem;'">{{
            data.value
          }}</span>
        </template>
        <template #cell()="data">
          <span
            :class="data.value.direction === 'R' ? 'retrograde' : ''"
            v-if="data.value.sign"
          >
            <span :class="decodeSymbolLetter(data.value.sign)[1]">
              <span class="physis-icon">{{
                decodeSymbolLetter(data.value.sign)[0]
              }}</span>
              {{ data.value.degree }}
              <span v-if="data.value.direction === 'R'">{{
                data.value.direction
              }}</span>
            </span>
          </span>
        </template>
      </b-table>
    </div>
    <div :style="'display:flex;'">
      <b-button
        variant="outline-primary"
        :disabled="selectedYear * 1 <= 2000"
        @click="selectedYear = (selectedYear * 1 - 1).toString()"
        >last
      </b-button>
      <b-form-select
        v-model="selectedYear"
        :options="yearOptions"
      ></b-form-select>
      <b-button
        variant="outline-primary"
        :disabled="selectedYear * 1 >= 2100"
        @click="selectedYear = (selectedYear * 1 + 1).toString()"
        >next
      </b-button>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import useEphemeris from "@/composables/useEphemeris";
import { decodeSymbolLetter, parseForSymbols } from "@/assets/helpers";

export default {
  name: "Ephemeris",
  setup() {
    const { ephemerisTableFields, ephemerisWithDayObjects } = useEphemeris();
    return {
      ephemerisTableFields,
      ephemerisWithDayObjects,
    };
  },
  data() {
    return {
      selectedYear: dayjs().format("YYYY"),
      yearOptions: [
        { value: "2000", text: "2000" },
        { value: "2001", text: "2001" },
        { value: "2002", text: "2002" },
        { value: "2003", text: "2003" },
        { value: "2004", text: "2004" },
        { value: "2005", text: "2005" },
        { value: "2006", text: "2006" },
        { value: "2007", text: "2007" },
        { value: "2008", text: "2008" },
        { value: "2009", text: "2009" },
        { value: "2010", text: "2010" },
        { value: "2011", text: "2011" },
        { value: "2012", text: "2012" },
        { value: "2013", text: "2013" },
        { value: "2014", text: "2014" },
        { value: "2015", text: "2015" },
        { value: "2016", text: "2016" },
        { value: "2017", text: "2017" },
        { value: "2018", text: "2018" },
        { value: "2019", text: "2019" },
        { value: "2020", text: "2020" },
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" },
        { value: "2026", text: "2026" },
        { value: "2027", text: "2027" },
        { value: "2028", text: "2028" },
        { value: "2029", text: "2029" },
        { value: "2030", text: "2030" },
        { value: "2031", text: "2031" },
        { value: "2032", text: "2032" },
        { value: "2033", text: "2033" },
        { value: "2034", text: "2034" },
        { value: "2035", text: "2035" },
        { value: "2036", text: "2036" },
        { value: "2037", text: "2037" },
        { value: "2037", text: "2037" },
        { value: "2038", text: "2038" },
        { value: "2039", text: "2039" },
        { value: "2040", text: "2040" },
        { value: "2041", text: "2041" },
        { value: "2042", text: "2042" },
        { value: "2043", text: "2043" },
        { value: "2044", text: "2044" },
        { value: "2045", text: "2045" },
        { value: "2046", text: "2046" },
        { value: "2047", text: "2047" },
        { value: "2048", text: "2048" },
        { value: "2049", text: "2049" },
        { value: "2050", text: "2050" },
        { value: "2051", text: "2051" },
        { value: "2052", text: "2052" },
        { value: "2053", text: "2053" },
        { value: "2054", text: "2054" },
        { value: "2055", text: "2055" },
        { value: "2056", text: "2056" },
        { value: "2057", text: "2057" },
        { value: "2058", text: "2058" },
        { value: "2059", text: "2059" },
        { value: "2060", text: "2060" },
        { value: "2061", text: "2061" },
        { value: "2062", text: "2062" },
        { value: "2063", text: "2063" },
        { value: "2064", text: "2064" },
        { value: "2065", text: "2065" },
        { value: "2066", text: "2066" },
        { value: "2067", text: "2067" },
        { value: "2068", text: "2068" },
        { value: "2069", text: "2069" },
        { value: "2070", text: "2070" },
        { value: "2071", text: "2071" },
        { value: "2072", text: "2072" },
        { value: "2073", text: "2073" },
        { value: "2074", text: "2074" },
        { value: "2075", text: "2075" },
        { value: "2076", text: "2076" },
        { value: "2077", text: "2077" },
        { value: "2078", text: "2078" },
        { value: "2079", text: "2079" },
        { value: "2080", text: "2080" },
        { value: "2081", text: "2081" },
        { value: "2082", text: "2082" },
        { value: "2083", text: "2083" },
        { value: "2084", text: "2084" },
        { value: "2085", text: "2085" },
        { value: "2086", text: "2086" },
        { value: "2087", text: "2087" },
        { value: "2088", text: "2088" },
        { value: "2089", text: "2089" },
        { value: "2090", text: "2090" },
        { value: "2091", text: "2091" },
        { value: "2092", text: "2092" },
        { value: "2093", text: "2093" },
        { value: "2094", text: "2094" },
        { value: "2095", text: "2095" },
        { value: "2096", text: "2096" },
        { value: "2097", text: "2097" },
        { value: "2098", text: "2098" },
        { value: "2099", text: "2099" },
        { value: "2100", text: "2100" },
      ],
    };
  },
  methods: {
    dayjs,
    decodeSymbolLetter,
    parseForSymbols,
  },
  computed: {
    selectedYearData() {
      return this.ephemerisWithDayObjects.find(
        (obj) => obj.year === this.selectedYear
      );
    },
  },
};
</script>
<style></style>
