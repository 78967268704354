<template>
  <div class="chartContainer" v-if="selectedDate">
    <div class="squareChart">
      <div class="signSquare water">
        <!-- 12 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(12)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare fire">
        <!-- 1 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(1)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare earth">
        <!-- 2 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(2)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare air">
        <!-- 3 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(3)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare air">
        <!-- 11 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(11)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="b-tl"></div>
      <div class="b-tr"></div>
      <div class="signSquare water">
        <!-- 4 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(4)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare earth">
        <!-- 10 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(10)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="b-bl"></div>
      <div class="b-br"></div>
      <div class="signSquare fire">
        <!-- 5 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(5)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare fire">
        <!-- 9 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(9)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare water">
        <!-- 8 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(8)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare air">
        <!-- 7 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(7)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
      <div class="signSquare earth">
        <!-- 6 -->
        <div
          v-for="(planet, index) in checkForPlanetsInThisSign(6)"
          :key="index"
        >
          <span :class="planet.direction === 'R' ? 'grayRetrogradeBkgd' : ''">
            <span
              class="physis-icon"
              :style="'color: ' + planetColorsMap[planet.planet] + '!important'"
            >
              {{ planet.planetSymbolLetter }}
            </span>
            <span>
              {{ planet.degrees }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useEphemeris from "@/composables/useEphemeris";
import {
  decodeSymbolLetter,
  encodePlanetSymbol,
  planetColorsMap,
} from "@/assets/helpers";

export default {
  name: "SouthIndianChartOfTheDay",
  setup() {
    const { currentDayObj, findDayObj } = useEphemeris();
    return {
      currentDayObj,
      findDayObj,
    };
  },
  props: ["selectedDate"],
  data() {
    return { planetColorsMap };
  },
  methods: {
    decodeSymbolLetter,
    encodePlanetSymbol,
    checkForPlanetsInThisSign(signNum) {
      let selectedDayData = this.findDayObj(this.selectedDate);
      let planetsHere = [];
      Object.keys(selectedDayData)
        .filter((key) => key !== "date")
        .forEach((planet) => {
          // check to see if the planet is in this sign
          // and pass through pertinent info
          if (
            this.decodeSymbolLetter(selectedDayData[planet].sign)[2] === signNum
          ) {
            let test = encodePlanetSymbol(planet);
            planetsHere.push({
              planet,
              planetSymbolLetter: test,
              degrees: selectedDayData[planet].degree,
              direction: selectedDayData[planet].direction,
            });
          }
        });
      return planetsHere;
    },
  },
};
</script>
<style>
.signSquare {
  border: 1px solid black;
  padding: 0.5rem;
}
.b-tl {
  border-top: 1px solid black;
  border-left: 1px solid black;
  background-color: lightgray;
}
.b-tr {
  border-top: 1px solid black;
  border-right: 1px solid black;
  background-color: lightgray;
}
.b-bl {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  background-color: lightgray;
}
.b-br {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  background-color: lightgray;
}
.grayRetrogradeBkgd {
  background-color: lightgray;
}
</style>
