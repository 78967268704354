export function encodePlanetSymbol(planet) {
  const ephemerisTableFields = {
    sun: "r",
    moon: "q",
    mercury: "w",
    venus: "e",
    mars: "t",
    jupiter: "y",
    saturn: "u",
    uranus: "i",
    neptune: "o",
    pluto: "p",
    rahu: "]",
    lilith: "Y",
    chiron: "T",
  };
  return ephemerisTableFields[planet];
}

export const natalChartPositions = {
  asc: { degree: "17°26", sign: "c" },
  sun: { degree: "5°43", sign: "k" },
  moon: { degree: "1°28", sign: "f" },
  mercury: { degree: "21°22", sign: "j" },
  venus: { degree: "5°54", sign: "j" },
  mars: { degree: "24°13", sign: "g" },
  jupiter: { degree: "12°19", sign: "i" },
  saturn: { degree: "22°43", sign: "g" },
  uranus: { degree: "19°34", sign: "h" },
  neptune: { degree: "7°16", sign: "i" },
  pluto: { degree: "8°26", sign: "g" },
  rahu: { degree: "18°47", sign: "b" },
  lilith: { degree: "14°02", sign: "k" },
  chiron: { degree: "", sign: "" },
};

export const signLetterCodeToNumber = {
  a: 1, //aries
  b: 2, //taurus
  c: 3,
  d: 4,
  e: 5,
  f: 6,
  g: 7,
  h: 8,
  i: 9,
  j: 10,
  k: 11,
  l: 12,
};

export function signAndDegreeTo360(planet, date) {
  let degreeString = date[planet].degree;
  degreeString = degreeString.slice(0, degreeString.indexOf("°"));
  let fullCircleDegree =
    degreeString * 1 + 30 * signLetterCodeToNumber[date[planet].sign] - 30;
  return fullCircleDegree;
}
export function decodeSymbolLetter(letter) {
  let decypher = {
    a: { code: "a", element: "fire", modality: "", number: 1 }, // aries
    b: { code: "s", element: "earth", modality: "", number: 2 }, // taurus
    c: { code: "d", element: "air", modality: "", number: 3 }, // gemini
    d: { code: "f", element: "water", modality: "", number: 4 }, // cancer
    e: { code: "g", element: "fire", modality: "", number: 5 }, // leo
    f: { code: "h", element: "earth", modality: "", number: 6 }, // virgo
    g: { code: "j", element: "air", modality: "", number: 7 }, // libra
    h: { code: "k", element: "water", modality: "", number: 8 }, // scorpoi
    i: { code: "l", element: "fire", modality: "", number: 9 }, // sag
    j: { code: ";", element: "earth", modality: "", number: 10 }, // cap
    k: { code: "'", element: "air", modality: "", number: 11 }, // aquarius
    l: { code: "z", element: "water", modality: "", number: 12 }, // pisces
  };
  return [
    decypher[letter].code,
    decypher[letter].element,
    decypher[letter].number,
  ];
}

export function parseForSymbols(string) {
  let splitString = string.split(/[abcdefghijklmn]/);
  if (splitString.length > 1) {
    let symbolCodes = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
    ];
    let symbolLetter;
    symbolCodes.forEach((letter) => {
      if (string.indexOf(letter) >= 0) {
        symbolLetter = letter;
      }
    });
    return [splitString[0], symbolLetter, splitString[1]];
  } else {
    return [string];
  }
}

export const monthLabelToNumberMap = {
  JANUARY: "1",
  FEBRUARY: "2",
  MARCH: "3",
  APRIL: "4",
  MAY: "5",
  JUNE: "6",
  JULY: "7",
  AUGUST: "8",
  SEPTEMBER: "9",
  OCTOBER: "10",
  NOVEMBER: "11",
  DECEMBER: "12",
};

export const daysOfTheWeekPlanets = [
  "sun",
  "moon",
  "mars",
  "mercury",
  "jupiter",
  "venus",
  "saturn",
];

export const planetColorsMap = {
  sun: "orange",
  moon: "cornflowerblue",
  mercury: "forestgreen",
  venus: "violet",
  mars: "red",
  jupiter: "gold",
  saturn: "black",
  uranus: "steelblue",
  neptune: "indigo",
  pluto: "saddlebrown",
  rahu: "dimgray",
  lilith: "darkred",
  chiron: "teal",
};

export function findNakshatra(planetName, planetObj) {
  let full360Degree = signAndDegreeTo360(planetName, planetObj);
  let naksatraMap = [
    {
      emoji: "🐴",
      label: "asvini",
      from: 0, //1
      to: 13, //1
      keywords: "healing, rejuvinating",
    },
    {
      emoji: "▽",
      label: "bharani",
      from: 13, //1
      to: 26, //1
      keywords: "dying, death, transformation",
    },
    {
      emoji: "🔪",
      label: "krittika",
      from: 26, //1
      to: 40, //2
      keywords: "burning, purifying, clarity",
    },
    {
      emoji: "🌾🛒",
      label: "rohini",
      from: 40, //2
      to: 53, //2
      keywords: "creating, culture",
    },
    {
      emoji: "🦌",
      label: "mrgasiras",
      from: 53, //2
      to: 66, //3
      keywords: "growth, ojas, procreative power",
    },
    {
      emoji: "👁️",
      label: "ardra",
      from: 66, //3
      to: 80, //3
      keywords: "anger, power of destruction",
    },
    {
      emoji: "🏹",
      label: "punarvasu",
      from: 80, //3
      to: 93, //4
      keywords: "learning, understanding, expanding",
    },
    {
      emoji: "🐄",
      label: "pusya",
      from: 93, //4
      to: 106, //4
      keywords: "knowing, awareness",
    },
    {
      emoji: "🐍",
      label: "aslesa",
      from: 106, //4
      to: 120, //5
      keywords: "deception, illusion, over-confidence",
    },
    {
      emoji: "🚗",
      label: "magha",
      from: 120, //5
      to: 133, //5
      keywords: "authority, karma",
    },
    {
      emoji: "🛌🍑",
      label: "purvaphalguni",
      from: 133, //5
      to: 146, //5
      keywords: "relationship & sexuality",
    },
    {
      emoji: "🛌💖",
      label: "uttaraphalguni",
      from: 146, //5
      to: 160, //6
      keywords: "love, marriage, family",
    },
    {
      emoji: "✋",
      label: "hasta",
      from: 160, //6
      to: 173, //6
      keywords: "waking up, realizing",
    },
    {
      emoji: "🦪",
      label: "citra",
      from: 173, //6
      to: 186, //7
      keywords: "building, planninig",
    },
    {
      emoji: "🐚",
      label: "svati",
      from: 186, //7
      to: 200, //7
      keywords: "movement, strength to build",
    },
    {
      emoji: "⛩️",
      label: "vishakha",
      from: 200, //
      to: 213, //
      keywords: "alliance, support systems",
    },
    {
      emoji: "💮",
      label: "anuradha",
      from: 213, //
      to: 226, //
      keywords: "friendship, fine detail",
    },
    {
      emoji: "🧿",
      label: "jyestha",
      from: 226, //
      to: 240, //
      keywords: "sensory control, controlling urges",
    },
    {
      emoji: "🦁ა",
      label: "mula",
      from: 240, //
      to: 253, //
      keywords: "breaking",
    },
    {
      emoji: "🐘",
      label: "purvasadha",
      from: 253, //
      to: 266, //
      keywords: "feeling, searching",
    },
    {
      emoji: "🛌",
      label: "uttarasadha",
      from: 266, //
      to: 280, //
      keywords: "nobility, good charachter",
    },
    {
      emoji: "🔱",
      label: "sravana",
      from: 280, //
      to: 293, //
      keywords: "all-pervasive, expanding",
    },
    {
      emoji: "🥁🎸",
      label: "dhanistha",
      from: 293, //
      to: 306, //
      keywords: "fame, shining, being seen",
    },
    {
      emoji: "○",
      label: "satabhisa",
      from: 306, //
      to: 320, //
      keywords: "punishment, repentance",
    },
    {
      emoji: "😈🛏️",
      label: "purvabhadra",
      from: 320, //
      to: 333, //
      keywords: "penance, Tapasya",
    },
    {
      emoji: "🔥🛏️",
      label: "uttarabhadra",
      from: 333, //
      to: 346, //
      keywords: "unseen, hidden",
    },
    {
      emoji: "🐟",
      label: "revati",
      from: 346, //
      to: 360, //
      keywords: "nourisher, safe & fruitful journeys",
    },
  ];
  let obj = naksatraMap.find(
    (naksatraObj) =>
      naksatraObj.from <= full360Degree && naksatraObj.to >= full360Degree
  );
  return [obj.emoji, obj.label, obj.keywords];
}

export function checkPlanetForAspects(planetName, dayObj) {
  // check each other planet in the day data to find aspects to primary planet
  let aspects = [];
  Object.keys(dayObj)
    .filter(
      (key) =>
        key !== planetName &&
        key !== "lilith" &&
        key !== "chiron" &&
        key !== "date"
    )
    .forEach((comparrisonPlanet) => {
      let primaryPlanetSign = decodeSymbolLetter(dayObj[planetName].sign)[2];
      let comparePlanetSign = decodeSymbolLetter(
        dayObj[comparrisonPlanet].sign
      )[2];
      let primaryPlanetDegree = dayObj[planetName].degree;
      let comparePlanetDegree = dayObj[comparrisonPlanet].degree;
      primaryPlanetDegree = primaryPlanetDegree.substring(
        0,
        primaryPlanetDegree.indexOf("°")
      );
      comparePlanetDegree = comparePlanetDegree.substring(
        0,
        comparePlanetDegree.indexOf("°")
      );
      let orb = comparePlanetDegree - primaryPlanetDegree;
      if (orb < 0) {
        orb = orb * -1;
      }
      if (comparrisonPlanet === "sun") {
        // sun aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "🌞",
            label: planetName + " conjunct sun ",
            orb: orb,
          });
        }
        // find the sign 7th from the sun
        let startingPoint = comparePlanetSign;
        for (let index = 0; index < 6; index++) {
          if (startingPoint < 12) {
            startingPoint++;
          } else {
            startingPoint = 1;
          }
        }
        if (primaryPlanetSign === startingPoint) {
          aspects.push({
            emoji: "🌞",
            label: planetName + " opposite sun (7th)",
            orb: orb,
          });
        }
      }
      if (comparrisonPlanet === "moon") {
        // moon aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "🌝",
            label: planetName + " conjunct moon",
            orb: orb,
          });
        }
        // find the sign 7th from the moon
        let startingPoint = comparePlanetSign;
        for (let index = 0; index < 6; index++) {
          if (startingPoint < 12) {
            startingPoint++;
          } else {
            startingPoint = 1;
          }
        }
        if (primaryPlanetSign === startingPoint) {
          aspects.push({
            emoji: "🌝",
            label: planetName + " opposite moon (7th)",
            orb: orb,
          });
        }
      }
      if (comparrisonPlanet === "mars") {
        // 4th 7th 8th
        // mars aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "🥵",
            label: planetName + " conjunct mars",
            orb: orb,
          });
        }
        let fullAspects = [4, 7, 8];
        fullAspects.forEach((num) => {
          let startingPoint = comparePlanetSign;
          // find the sign NUMth from the mars
          for (let index = 0; index < num - 1; index++) {
            if (startingPoint < 12) {
              startingPoint++;
            } else {
              startingPoint = 1;
            }
          }
          if (primaryPlanetSign === startingPoint) {
            aspects.push({
              emoji: "🥵",
              label: planetName + " aspected by mars (" + num + "th)",
              orb: orb,
            });
          }
        });
      }
      if (comparrisonPlanet === "mercury") {
        // mercury aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "😉",
            label: planetName + " conjunct mercury",
            orb: orb,
          });
        }
        // find the sign 7th from the mercury
        let startingPoint = comparePlanetSign;
        for (let index = 0; index < 6; index++) {
          if (startingPoint < 12) {
            startingPoint++;
          } else {
            startingPoint = 1;
          }
        }
        if (primaryPlanetSign === startingPoint) {
          aspects.push({
            emoji: "😉",
            label: planetName + " opposite mercury (7th)",
            orb: orb,
          });
        }
      }
      if (comparrisonPlanet === "jupiter") {
        // 5th 7th 9th
        // jupiter aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "😇",
            label: planetName + " conjunct jupiter",
            orb: orb,
          });
        }
        let fullAspects = [5, 7, 9];
        fullAspects.forEach((num) => {
          let startingPoint = comparePlanetSign;
          // find the sign NUMth from the jupiter
          for (let index = 0; index < num - 1; index++) {
            if (startingPoint < 12) {
              startingPoint++;
            } else {
              startingPoint = 1;
            }
          }
          if (primaryPlanetSign === startingPoint) {
            aspects.push({
              emoji: "😇",
              label: planetName + " aspected by jupiter (" + num + "th)",
              orb: orb,
            });
          }
        });
      }
      if (comparrisonPlanet === "venus") {
        // venus aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "😍",
            label: planetName + " conjunct venus",
            orb: orb,
          });
        }
        // find the sign 7th from the venus
        let startingPoint = comparePlanetSign;
        for (let index = 0; index < 6; index++) {
          if (startingPoint < 12) {
            startingPoint++;
          } else {
            startingPoint = 1;
          }
        }
        if (primaryPlanetSign === startingPoint) {
          aspects.push({
            emoji: "😍",
            label: planetName + " opposite venus (7th)",
            orb: orb,
          });
        }
      }
      if (comparrisonPlanet === "saturn") {
        // 3rd 7th 10th
        // saturn aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "😬",
            label: planetName + " conjunct saturn",
            orb: orb,
          });
        }
        let fullAspects = [3, 7, 10];
        fullAspects.forEach((num) => {
          let startingPoint = comparePlanetSign;
          // find the sign NUMth from the saturn
          for (let index = 0; index < num - 1; index++) {
            if (startingPoint < 12) {
              startingPoint++;
            } else {
              startingPoint = 1;
            }
          }
          if (primaryPlanetSign === startingPoint) {
            aspects.push({
              emoji: "😬",
              label: planetName + " aspected by saturn (" + num + "th)",
              orb: orb,
            });
          }
        });
      }
      if (comparrisonPlanet === "rahu") {
        // 5rd 7th 9th (2nd)
        // rahu aspects the 7th sign from where it is now
        if (primaryPlanetSign === comparePlanetSign) {
          aspects.push({
            emoji: "😈",
            label: planetName + " conjunct rahu",
            orb: orb,
          });
        }
        let fullAspects = [5, 7, 9, 2];
        fullAspects.forEach((num) => {
          let startingPoint = comparePlanetSign;
          // find the sign NUMth from the rahu
          for (let index = 0; index < num - 1; index++) {
            if (startingPoint < 12) {
              startingPoint++;
            } else {
              startingPoint = 1;
            }
          }
          if (primaryPlanetSign === startingPoint) {
            aspects.push({
              emoji: "😈",
              label: planetName + " aspected by rahu (" + num + "th)",
              orb: orb,
            });
          }
        });
      }
    });
  return aspects;
}

export function checkPlanetForStrengths(planetName, planetObj, planetDegree) {
  if (planetDegree) {
    planetDegree = planetDegree.substring(0, planetDegree.indexOf("°"));
  }
  if (planetName === "sun") {
    if (planetObj.sign === "e") {
      //the sun has rulership in leo
      //0-20 office
      //20-30 rulership
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 20) {
        return { emoji: "🤖", label: "office!" };
      } else {
        return { emoji: "👑", label: "rulership" };
      }
    }
    if (planetObj.sign === "a") {
      //the sun is exalted in aries 10
      if (planetDegree * 1 === 10) {
        return { emoji: "⭐", label: "exaltation" };
      } else {
        return { emoji: "😎", label: "sign of a friend" };
      }
    }
    if (planetObj.sign === "k") {
      //the sun has debilitated in aqu
      return {
        emoji: "😡",
        label: "sign of an enemy",
      }; //return "debilitation 😢";
    }
    if (planetObj.sign === "g") {
      //the sun is fallen in libra 10
      if (planetDegree * 1 === 10) {
        return { emoji: "😖", label: "fallen" };
      } else {
        return {
          emoji: "😡",
          label: "sign of an enemy",
        };
      }
    }
    if (planetObj.sign === "d") {
      //the sun is friend w moon / cancer
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (planetObj.sign === "h") {
      //the sun is friend w mars / scorpio
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (planetObj.sign === "i" || planetObj.sign === "l") {
      //the sun is friend w jupiter / pisces sag
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (planetObj.sign === "c" || planetObj.sign === "f") {
      //the sun is neutral w mercury / gemini virgo
      return { emoji: "😐", label: "sign of a neutral" };
    }
    if (planetObj.sign === "b") {
      //the sun is evemy w venus / taurus
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
    if (planetObj.sign === "k" || planetObj.sign === "j") {
      //the sun is evemy w saturn / aqu cap
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
  }
  if (planetName === "moon") {
    if (planetObj.sign === "d") {
      //the moon has rulership in cancer
      return { emoji: "👑", label: "rulership" };
    }
    if (planetObj.sign === "b") {
      //the moon is exalted in taurus 0-3
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 3) {
        return { emoji: "⭐", label: "exaltation" };
      } else {
        return { emoji: "🤖", label: "office!" };
      }
    }
    if (planetObj.sign === "j") {
      //the moon has debilitated in cap
      return { emoji: "😐", label: "sign of a neutral" }; //return "debilitated 😢";
    }
    if (planetObj.sign === "h") {
      //the moon is fallen in scoropio
      if (planetDegree * 1 === 3) {
        return { emoji: "😖", label: "fallen" };
      }
    }
    if (
      planetObj.sign === "e" ||
      planetObj.sign === "c" ||
      planetObj.sign === "f"
    ) {
      //the moon is friends w sun & mercury leo gemini virgo
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (
      planetObj.sign === "a" ||
      planetObj.sign === "h" ||
      planetObj.sign === "l" ||
      planetObj.sign === "i" ||
      planetObj.sign === "b" ||
      planetObj.sign === "g" ||
      planetObj.sign === "j" ||
      planetObj.sign === "k"
    ) {
      //the moon is neutral w mars, jupiter, venus, saturn
      // aires, scorpio, pisc, sag, taurus, libra, aqu, cap
      return { emoji: "😐", label: "sign of a neutral" };
    }
  }
  if (planetName === "mars") {
    if (planetObj.sign === "a") {
      //mars has rulership in aries
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 12) {
        return { emoji: "🤖", label: "office!" };
      } else {
        return { emoji: "👑", label: "rulership" };
      }
    }
    if (planetObj.sign === "h") {
      //mars has rulership in scorpio
      return { emoji: "👑", label: "rulership" };
    }
    if (planetObj.sign === "j") {
      //mars is exalted in cap 28
      if (planetDegree * 1 === 28) {
        return { emoji: "⭐", label: "exaltation" };
      }
    }
    if (planetObj.sign === "g" || planetObj.sign === "b") {
      //mars has debilitated in libra and taurus
      return { emoji: "😐", label: "sign of a neutral" }; //return "debilitated 😢";
    }
    if (planetObj.sign === "d") {
      //mars is fallen in cancer 28
      if (planetDegree * 1 === 28) {
        return { emoji: "😖", label: "fallen" };
      }
    }
    if (
      planetObj.sign === "e" ||
      planetObj.sign === "d" ||
      planetObj.sign === "i" ||
      planetObj.sign === "l"
    ) {
      //mars is friends w sun & moon jupiter leo cancer pisc sag
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (
      planetObj.sign === "b" ||
      planetObj.sign === "g" ||
      planetObj.sign === "j" ||
      planetObj.sign === "k"
    ) {
      //mars is neutral w venus saturn
      // taurus, libra, aqu, cap
      return { emoji: "😐", label: "sign of a neutral" };
    }
    if (planetObj.sign === "c" || planetObj.sign === "f") {
      //mars is enemy w mercury gem virg
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
  }
  if (planetName === "mercury") {
    if (planetObj.sign === "f") {
      // mercury has rulership and exallation in virgo
      // exaltation 0-15
      // office 15-20
      // rulership 20-30
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 15) {
        return { emoji: "⭐", label: "exaltation" };
      }
      if (planetDegree * 1 >= 15 && planetDegree * 1 <= 20) {
        return { emoji: "🤖", label: "office!" };
      }
      if (planetDegree * 1 >= 20 && planetDegree * 1 <= 30) {
        return { emoji: "👑", label: "rulership" };
      }
    }
    if (planetObj.sign === "c") {
      //mercury has rulership in gemini
      return { emoji: "👑", label: "rulership" };
    }
    if (planetObj.sign === "l") {
      //mercury has fallen in pisces 15
      if (planetDegree * 1 === 15) {
        return { emoji: "😖", label: "fallen" };
      }
    }
    if (planetObj.sign === "i") {
      //mercury has debilitated in sag
      return { emoji: "😐", label: "sign of a neutral" }; //return "debilitated 😢";
    }
    if (
      planetObj.sign === "e" ||
      planetObj.sign === "b" ||
      planetObj.sign === "g"
    ) {
      //mercury is friends w sun venus leo taur libra
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (
      planetObj.sign === "a" ||
      planetObj.sign === "h" ||
      planetObj.sign === "i" ||
      planetObj.sign === "l" ||
      planetObj.sign === "j" ||
      planetObj.sign === "k"
    ) {
      //mercury is neutral w mars jupiter saturn
      // aries, scorp, sag, pisc, aqu, cap
      return { emoji: "😐", label: "sign of a neutral" };
    }
    if (planetObj.sign === "d") {
      //mercury is enemy w moon cancer
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
  }
  if (planetName === "jupiter") {
    if (planetObj.sign === "i") {
      //jupiter has rulership in sag
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 10) {
        return { emoji: "🤖", label: "office!" };
      } else {
        return { emoji: "👑", label: "rulership" };
      }
    }
    if (planetObj.sign === "l") {
      //jupiter has rulership in pisces
      return { emoji: "👑", label: "rulership" };
    }
    if (planetObj.sign === "d") {
      //jupiter is exalted in cancer 5
      if (planetDegree * 1 === 5) {
        return { emoji: "⭐", label: "exaltation" };
      }
    }
    if (planetObj.sign === "c" || planetObj.sign === "f") {
      //jupiter debilitated in gemini and virgo
      return {
        emoji: "😡",
        label: "sign of an enemy",
      }; //return "debilitated 😢";
    }
    if (planetObj.sign === "j") {
      //jupiter is fallen in cap 5
      if (planetDegree * 1 === 5) {
        return { emoji: "😖", label: "fallen" };
      }
    }
    if (
      planetObj.sign === "e" ||
      planetObj.sign === "d" ||
      planetObj.sign === "a" ||
      planetObj.sign === "h"
    ) {
      //jupiter is friends w sun & moon mars leo cancer aries scorp
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (planetObj.sign === "j" || planetObj.sign === "k") {
      //jupiter is neutral w saturn  aqu, cap
      return { emoji: "😐", label: "sign of a neutral" };
    }
    if (
      planetObj.sign === "c" ||
      planetObj.sign === "f" ||
      planetObj.sign === "b" ||
      planetObj.sign === "g"
    ) {
      //jupiter is enemy w mercury venus taur libra gem virg
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
  }
  if (planetName === "venus") {
    if (planetObj.sign === "b") {
      //venus has rulership in taurus
      return { emoji: "👑", label: "rulership" };
    }
    if (planetObj.sign === "g") {
      //venus has rulership in libra
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 15) {
        return { emoji: "🤖", label: "office!" };
      } else {
        return { emoji: "👑", label: "rulership" };
      }
    }
    if (planetObj.sign === "l") {
      //venus is exalted in pisces 27
      if (planetDegree * 1 === 27) {
        return { emoji: "⭐", label: "exaltation" };
      } else {
        return { emoji: "😐", label: "sign of a neutral" };
      }
    }
    if (
      planetObj.sign === "h" ||
      planetObj.sign === "a" ||
      planetObj.sign === "i"
    ) {
      //venus has debilitated in scorpio and aris
      return { emoji: "😐", label: "sign of a neutral" }; //return "debilitated 😢";
    }
    if (planetObj.sign === "f") {
      //venus is fallen in virgo 27
      if (planetDegree * 1 === 27) {
        return { emoji: "😖", label: "fallen" };
      }
    }
    if (
      planetObj.sign === "c" ||
      planetObj.sign === "f" ||
      planetObj.sign === "j" ||
      planetObj.sign === "k"
    ) {
      //venus is friends w mercury saturn gem virg cap aqu
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (
      planetObj.sign === "a" ||
      planetObj.sign === "h" ||
      planetObj.sign === "c" ||
      planetObj.sign === "f"
    ) {
      //venus is neutral w mars jupiter aries scorp sag pisc
      return { emoji: "😐", label: "sign of a neutral" };
    }
    if (planetObj.sign === "d" || planetObj.sign === "e") {
      //venus is enemy w sun moon leo cancer
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
  }
  if (planetName === "saturn") {
    if (planetObj.sign === "j") {
      //saturn has rulership in cap
      return { emoji: "👑", label: "rulership" };
    }
    if (planetObj.sign === "k") {
      //saturn has rulership in aqu
      if (planetDegree * 1 >= 0 && planetDegree * 1 <= 20) {
        return { emoji: "🤖", label: "office!" };
      } else {
        return { emoji: "👑", label: "rulership" };
      }
    }
    if (planetObj.sign === "g") {
      //saturn is exalted in libra 20
      if (planetDegree * 1 === 20) {
        return { emoji: "⭐", label: "exaltation" };
      }
    }
    if (planetObj.sign === "l" || planetObj.sign === "i") {
      //saturn has debilitated in cancer and leo
      return {
        emoji: "😡",
        label: "sign of an enemy",
      }; //return "debilitated 😢";
    }
    if (planetObj.sign === "a") {
      //saturn is fallen in aries 20
      if (planetDegree * 1 === 20) {
        return { emoji: "😖", label: "fallen" };
      }
    }
    if (
      planetObj.sign === "c" ||
      planetObj.sign === "f" ||
      planetObj.sign === "b" ||
      planetObj.sign === "g"
    ) {
      //saturn is friends w merc venus gem virg tau libr
      return { emoji: "😎", label: "sign of a friend" };
    }
    if (planetObj.sign === "i" || planetObj.sign === "l") {
      //saturn is neutral w jupiter sag pisc
      return { emoji: "😐", label: "sign of a neutral" };
    }
    if (
      planetObj.sign === "e" ||
      planetObj.sign === "d" ||
      planetObj.sign === "a" ||
      planetObj.sign === "h"
    ) {
      //saturn is enemy w sun moon mars leo cancer aries scorp
      return {
        emoji: "😡",
        label: "sign of an enemy",
      };
    }
  }
}
