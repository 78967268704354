<template>
  <div>
    <div>
      <span
        :class="
          zodiacMode === 'siderealElementalSignsBackground'
            ? 'activeViewMode'
            : ''
        "
        @click="zodiacMode = 'siderealElementalSignsBackground'"
      >
        SIDEREAL
      </span>
      <span
        :class="
          zodiacMode === 'tropicalElementalSignsBackground'
            ? 'activeViewMode'
            : ''
        "
        @click="zodiacMode = 'tropicalElementalSignsBackground'"
      >
        TROPICAL
      </span>
      <span
        :class="
          zodiacMode === 'comboElementalSignsBackground' ? 'activeViewMode' : ''
        "
        @click="zodiacMode = 'comboElementalSignsBackground'"
      >
        COMBO
      </span>
    </div>
    <br />
    <div class="yearsScroll" :style="'width:' + years.length * 1160 + 'px'">
      <div
        class="yearChartContainer"
        v-for="(year, index) in years"
        :key="index"
      >
        <YearLong360LineChart :year="year" :zodiacMode="zodiacMode" />
      </div>
    </div>
  </div>
</template>

<script>
import YearLong360LineChart from "../components/charts/YearLong360LineChart.vue";

export default {
  name: "YearsScroll",
  components: {
    YearLong360LineChart,
  },
  data() {
    return {
      zodiacMode: "siderealElementalSignsBackground",
      years: [
        2022, 2023, 2024, 2025, 2026,
        //  2027, 2028, 2029, 2030, 2031, 2032, 2033,
        // 2034,
      ],
    };
  },
};
</script>
<style>
.yearsScroll {
  display: flex;
}
.yearChartContainer {
  min-width: "1160px";
}
</style>
