<template>
  <div class="topBox" v-if="selectedDate">
    <div
      v-for="(planet, index) in daysOfTheWeekPlanets"
      :key="index"
      class="planetDetailsBox"
      :class="
        currentlySelectedDay[planet].direction === 'R' ? 'retrograde' : ''
      "
    >
      <div>
        <span :class="decodeSymbolLetter(currentlySelectedDay[planet].sign)[1]">
          <span class="physis-icon">{{ encodePlanetSymbol(planet) }}</span>
          in
          <span class="physis-icon">{{
            decodeSymbolLetter(currentlySelectedDay[planet].sign)[0]
          }}</span>
          {{ currentlySelectedDay[planet].degree }} <br />
        </span>
        <p>
          <span
            v-if="
              checkPlanetForStrengths(
                planet,
                currentlySelectedDay[planet],
                currentlySelectedDay[planet].degree
              ) &&
              checkPlanetForStrengths(
                planet,
                currentlySelectedDay[planet],
                currentlySelectedDay[planet].degree
              ).emoji
            "
            :style="'font-size:1.5rem'"
            >{{
              checkPlanetForStrengths(
                planet,
                currentlySelectedDay[planet],
                currentlySelectedDay[planet].degree
              ).emoji
            }}</span
          ><br />
          <span
            v-if="
              checkPlanetForStrengths(
                planet,
                currentlySelectedDay[planet],
                currentlySelectedDay[planet].degree
              ) &&
              checkPlanetForStrengths(
                planet,
                currentlySelectedDay[planet],
                currentlySelectedDay[planet].degree
              ).label
            "
          >
            {{
              checkPlanetForStrengths(
                planet,
                currentlySelectedDay[planet],
                currentlySelectedDay[planet].degree
              ).label
            }}
          </span>
        </p>
        <p
          v-for="(aspect, index) in checkPlanetForAspects(
            planet,
            currentlySelectedDay
          )"
          :key="index"
        >
          <span :style="'font-size:1.5rem'" v-if="aspect && aspect.emoji">{{
            aspect.emoji
          }}</span>
          <span>{{ splitAspectLabel(aspect.label)[0] }}</span>
          <span class="regularOrb">{{
            splitAspectLabel(aspect.label)[1]
          }}</span>
          <span :class="makeFontWeightBoldOnCloseOrb(aspect.orb)"
            >({{ aspect.orb }})</span
          >
        </p>
      </div>
      <p>
        <span :style="'font-size:1.5rem'">{{
          findNakshatra(planet, currentlySelectedDay)[0]
        }}</span
        ><br />
        {{ findNakshatra(planet, currentlySelectedDay)[1] }}
        <br />
        {{ findNakshatra(planet, currentlySelectedDay)[2] }}
      </p>
    </div>
  </div>
</template>
<script>
import useEphemeris from "@/composables/useEphemeris";
import {
  encodePlanetSymbol,
  decodeSymbolLetter,
  checkPlanetForStrengths,
  checkPlanetForAspects,
  daysOfTheWeekPlanets,
  findNakshatra,
} from "@/assets/helpers";
// import dayjs from "dayjs";

export default {
  name: "StrengthsDetailsBoxes",
  setup() {
    const { findDayObj } = useEphemeris();
    return {
      findDayObj,
    };
  },
  props: ["selectedDate"],
  data() {
    return {
      daysOfTheWeekPlanets,
    };
  },
  computed: {
    currentlySelectedDay() {
      return this.findDayObj(this.selectedDate);
    },
  },
  methods: {
    decodeSymbolLetter,
    encodePlanetSymbol,
    checkPlanetForStrengths,
    checkPlanetForAspects,
    findNakshatra,
    splitAspectLabel(label) {
      if (label.indexOf("(") > 0) {
        return [
          label.substring(0, label.indexOf("(")),
          label.substring(label.indexOf("("), label.length),
        ];
      } else {
        return [label, ""];
      }
    },
    makeFontWeightBoldOnCloseOrb(orb) {
      if (orb === 0) {
        return "extraBoldOrb";
      }
      if (orb > 0 && orb <= 5) {
        return "boldOrb";
      }
      if (orb > 5 && orb <= 15) {
        return "regularOrb";
      }
      if (orb > 15) {
        return "lightOrb";
      }
    },
  },
};
</script>
<style></style>
