<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                offset: true,
              },
            ],
          },
        };
      },
    },
  },
  methods: {
    downloadAsImage() {
      const a = document.createElement("a");
      // chartjs vue documentation recommends accessing the obj like so:
      // https://vue-chartjs.org/api/#chart-js-object
      a.href = this.$data._chart.toBase64Image();
      a.download = "chart.png";
      a.click();
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
